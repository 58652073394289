<template>
  <div class="balance">
    <div class="balance__rows">
      <div class="row">
        <div class="label">
          {{ getContent(walletContent, defaultLocaleWalletContent, 'balanceLabel') }}
        </div>

        <div class="value">{{ balanceFormat.amount }} {{ balanceFormat.currency }}</div>
      </div>

      <div v-if="props.withdraw" class="row">
        <div class="label">{{ getContent(walletContent, defaultLocaleWalletContent, 'withdraw.withdrawLabel') }}</div>

        <div class="value">{{ withdrawalBalanceFormat.amount }} {{ withdrawalBalanceFormat.currency }}</div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { IWalletModal } from '~/types';

  const props = defineProps({
    withdraw: {
      type: Boolean,
      default: false,
    },
  });

  const walletContent: Maybe<IWalletModal> = inject('walletContent');
  const defaultLocaleWalletContent: Maybe<IWalletModal> = inject('defaultLocaleWalletContent');
  const walletStore = useWalletStore();
  const { activeAccount } = storeToRefs(walletStore);
  const { formatBalance, getContent } = useProjectMethods();

  const balanceFormat = computed(() => formatBalance(activeAccount.value?.currency, activeAccount.value?.balance));
  const withdrawalBalanceFormat = computed(() =>
    formatBalance(activeAccount.value?.currency, activeAccount.value?.withdrawalBalance)
  );
</script>

<style src="~/assets/styles/components/balance.scss" lang="scss" />
